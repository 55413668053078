import { createRouter, createWebHistory } from 'vue-router'
import * as Sentry from '@sentry/vue'
import { watch } from 'vue'
import { useModal } from '@/modal'
import { useToast } from 'vue-toastification'
import { storeToRefs } from 'pinia'
// import { intersection } from 'lodash-es'

import PageNotFound from '@/views/PageNotFound.vue'
// import FeatureUpsellModal from '@/components/admin/modals/FeatureUpsell.vue'
import { useUserStore } from './store'
import { useProgressBar } from './nprogress'

const progressBar = useProgressBar()
const toast = useToast()

const router = createRouter({
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      component: () => import('@/containers/LoginContainer.vue'),
      children: [
        {
          name: 'Login',
          path: '',
          component: () => import('@/views/login/LoginView.vue'),
        },
        {
          name: 'Login-MagicLink',
          path: 'magicLink',
          component: () => import('@/views/login/LoginView.vue'),
        },
        {
          name: 'Login-Impersonation',
          path: 'impersonate',
          component: () => import('@/views/login/LoginView.vue'),
        },
        {
          name: 'ExchangeSLT',
          path: 'exchange',
          component: () => import('@/views/login/ExchangeSLT.vue'),
        },
        {
          name: 'Login-ResetPassword',
          path: 'resetPassword',
          component: () => import('@/views/login/PasswordReset.vue'),
        },
      ],
    },
    {
      path: '/confirmInvite',
      component: () => import('@/containers/LoginContainer.vue'),
      children: [
        {
          name: 'Onboarding_ConfirmInvite',
          path: '',
          component: () => import('@/views/onboard/ConfirmInvite.vue'),
        },
      ],
    },
    {
      name: '3PTokenFlow',
      path: '/3p-authorize',
      component: () => import('@/views/client/3PTokenFlow.vue'),
      meta: {
        requiredRoles: ['admin'],
        requireAuth: true,
      },
    },
    {
      path: '/legacy-migrate',
      component: () => import('@/containers/MigrationContainer.vue'),
      children: [
        {
          name: 'Migration_Start',
          path: '',
          component: () => import('@/views/migration/GetStarted.vue'),
        },
      ],
    },
    {
      path: '/welcome',
      meta: {
        requireAuth: true,
        isOnboard: true,
      },
      component: () => import('@/containers/OnboardContainer.vue'),
      children: [
        {
          name: 'Onboarding_Welcome',
          path: '',
          component: () => import('@/views/onboard/OnboardWelcome.vue'),
        },
        {
          name: 'Onboarding_Privacy',
          path: 'privacy',
          component: () => import('@/views/onboard/OnboardPrivacy.vue'),
        },
        {
          name: 'Onboarding_RewardCountry',
          path: 'rewards',
          component: () => import('@/views/onboard/OnboardRewardCountry.vue'),
        },
        {
          name: 'Onboarding_Complete',
          path: 'complete',
          component: () => import('@/views/onboard/OnboardComplete.vue'),
        },
      ],
    },
    {
      path: '/admin',
      component: () => import('@/containers/AdminContainer.vue'),
      meta: {
        requiredRoles: ['admin'],
        requireAuth: true,
      },
      children: [
        {
          name: 'Admin_Home',
          path: '',
          component: () => import('@/views/admin/HomeView.vue'),
        },
        {
          path: 'recognition',
          children: [
            {
              name: 'Admin_Recognition-Home',
              path: '',
              component: () =>
                import('@/views/admin/recognition/RecognitionHome.vue'),
            },
            {
              name: 'Admin_Recognition-Settings',
              path: 'settings',
              component: () =>
                import('@/views/admin/recognition/RecognitionSettings.vue'),
            },
          ],
        },
        {
          path: 'nominations',
          children: [
            {
              name: 'Admin_Nominations-Home',
              path: '',
              component: () =>
                import('@/views/admin/nominations/NominationsHome.vue'),
            },
            {
              name: 'Admin_Nominations-Settings',
              path: 'settings',
              component: () =>
                import('@/views/admin/nominations/NominationsSettings.vue'),
            },
            {
              name: 'Admin_Nominations-Campaign',
              path: 'campaign/:id',
              component: () =>
                import('@/views/admin/nominations/NominationCampaign.vue'),
            },
          ],
        },
        {
          path: 'challenges',
          children: [
            {
              name: 'Admin_Challenges-Home',
              path: '',
              component: () =>
                import('@/views/admin/challenges/ChallengesHome.vue'),
            },

            {
              name: 'Admin_Challenges-Settings',
              path: 'settings',
              component: () =>
                import('@/views/admin/challenges/ChallengesSettings.vue'),
            },
            {
              name: 'Admin_Challenges-Challenge',
              path: ':id',
              component: () =>
                import('@/views/admin/challenges/ChallengesChallenge.vue'),
            },
          ],
        },
        {
          path: 'rewards',
          children: [
            {
              name: 'Admin_Rewards-Home',
              path: '',
              component: () => import('@/views/admin/rewards/RewardsHome.vue'),
            },
            {
              name: 'Admin_Rewards-Billing',
              path: 'billing',
              component: () =>
                import('@/views/admin/rewards/RewardsBilling.vue'),
            },
            {
              name: 'Admin_Rewards-Custom_Catalog',
              path: 'custom-catalog',
              component: () =>
                import('@/views/admin/rewards/CustomCatalog.vue'),
            },
            {
              name: 'Admin_Rewards-Settings',
              path: 'settings',
              component: () =>
                import('@/views/admin/rewards/RewardsSettings.vue'),
            },
          ],
        },
        {
          path: 'users',
          children: [
            {
              name: 'Admin_Users',
              path: '',
              component: () => import('@/views/admin/users/ActiveUsers.vue'),
            },
            {
              name: 'Admin_Users-Invited',
              path: 'invited',
              component: () => import('@/views/admin/users/InvitedUsers.vue'),
            },
            {
              name: 'Admin_Users-Inactive',
              path: 'inactive',
              component: () => import('@/views/admin/users/InactiveUsers.vue'),
            },
            {
              name: 'Admin_Users-Segments',
              path: 'segments',
              component: () => import('@/views/admin/users/UserSegments.vue'),
            },
            {
              path: ':userId',
              children: [
                {
                  name: 'Admin_Users-UserProfile',
                  path: 'profile',
                  component: () =>
                    import('@/views/admin/users/SingleUser/UserProfile.vue'),
                },
                {
                  name: 'Admin_Users-UserActivity',
                  path: '',
                  component: () =>
                    import('@/views/admin/users/SingleUser/UserActivity.vue'),
                },
              ],
            },
          ],
        },
        {
          path: 'spot-celebrations',
          children: [
            {
              name: 'Admin_Gifting-Home',
              path: '',
              component: () => import('@/views/admin/gifting/GiftingHome.vue'),
            },
            {
              name: 'Admin_Gifting-Settings',
              path: 'settings',
              component: () =>
                import('@/views/admin/gifting/GiftingSettings.vue'),
            },
          ],
        },
        {
          path: 'celebrations',
          meta: {
            requiredTeamPlan: ['PRO', 'CELEBRATE'],
          },
          children: [
            {
              name: 'Admin_Celebrations-Upcoming',
              path: '',
              component: () =>
                import('@/views/admin/celebrations/UpcomingCelebrations.vue'),
            },
            {
              name: 'Admin_Celebrations-AnniversarySettings',
              path: 'anniversaries',
              component: () =>
                import('@/views/admin/celebrations/AnniversarySettings.vue'),
            },
            {
              name: 'Admin_Celebrations-BirthdaySettings',
              path: 'birthdays',
              component: () =>
                import('@/views/admin/celebrations/BirthdaySettings.vue'),
            },
            {
              name: 'Admin_Celebrations-NewHireSettings',
              path: 'new-hires',
              component: () =>
                import('@/views/admin/celebrations/NewHireSettings.vue'),
            },
          ],
        },
        {
          path: 'settings',
          children: [
            {
              name: 'Admin_Settings-Home',
              path: '',
              component: () =>
                import('@/views/admin/settings/SettingsHome.vue'),
            },
            {
              name: 'Admin_Settings-Subscription',
              path: 'subscription',
              component: () =>
                import('@/views/admin/settings/SubscriptionBilling.vue'),
            },
            {
              name: 'Admin_Settings-Integrations',
              path: 'integrations',
              component: () =>
                import('@/views/admin/settings/IntegrationList.vue'),
            },
            {
              name: 'Admin_Settings-Integration_Redirect',
              path: 'integrations/redirects/:integration',
              component: () =>
                import('@/views/admin/settings/IntegrationRedirect.vue'),
            },
            {
              name: 'Admin_Settings-SSO',
              path: 'sso',
              component: () => import('@/views/admin/settings/SSOSettings.vue'),
            },
          ],
        },
        {
          path: 'reports',
          children: [
            {
              name: 'Reports-Home',
              path: '',
              component: () =>
                import('@/views/admin/reports/GenerateReports.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/containers/ClientContainer.vue'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          name: 'Home',
          path: '',
          component: () => import('@/views/client/HomeView.vue'),
        },
        {
          name: 'Client_UserSettings',
          path: 'settings',
          component: () => import('@/views/client/UserSettings.vue'),
        },
        {
          path: 'spot-celebrations',
          meta: {
            requiredPlan: ['CELEBRATE', 'PRO'],
          },
          children: [
            {
              name: 'Client_Gifting-Home',
              path: '',
              component: () => import('@/views/client/gifting/GiftingHome.vue'),
              props: {
                forUsers: true,
              },
            },
            {
              name: 'Client_Gifting-Moderation',
              path: 'moderate',
              component: () =>
                import('@/views/client/gifting/ModerationQueue.vue'),
            },
            {
              name: 'Client_Gifting-Sent',
              path: 'sent',
              component: () => import('@/views/client/gifting/SentGifts.vue'),
            },
          ],
        },
        {
          path: 'users/:id',
          redirect: (to) => ({
            name: 'Home',
            query: { user: to.params.id },
          }),
        },
        {
          path: 'challenges',
          meta: {
            requiredPlan: ['PRO'],
          },
          children: [
            {
              name: 'Client_Challenges-Home',
              path: '',
              component: () =>
                import('@/views/client/challenges/ChallengesHome.vue'),
            },
            {
              name: 'Client_Challenges-Moderation',
              path: 'moderate',
              meta: {
                requiredRoles: ['challenge_moderator'],
              },
              component: () =>
                import('@/views/client/challenges/ChallengesModeration.vue'),
            },
          ],
        },
        {
          path: 'nominations',
          meta: {
            requiredPlan: ['PRO'],
          },
          children: [
            {
              name: 'Client_Nominations-Home',
              path: '',
              component: () =>
                import('@/views/client/nominations/NominationsHome.vue'),
            },
          ],
        },
        {
          path: 'rewards',
          children: [
            {
              name: 'Client_Rewards-Home',
              path: '',
              component: () => import('@/views/client/rewards/RewardsHome.vue'),
            },
            {
              name: 'Client_Rewards-GiftsToClaim',
              path: 'claim-gifts',
              component: () =>
                import('@/views/client/rewards/GiftsToClaim.vue'),
            },
            {
              name: 'Client_Rewards-GiftCards-List',
              path: 'gift-cards',
              component: () =>
                import('@/views/client/rewards/GiftCardList.vue'),
            },
            {
              name: 'Client_Rewards-Cashout',
              path: 'cashout',
              component: () =>
                import('@/views/client/rewards/CashoutRequest.vue'),
            },
            {
              path: 'donate',
              children: [
                {
                  name: 'Client_Rewards-Charities-Home',
                  path: '',
                  component: () =>
                    import('@/views/client/rewards/charities/CharityHome.vue'),
                },
                {
                  name: 'Client_Rewards-Charities-CharitySingle',
                  path: 'charity/:charityEin',
                  component: () =>
                    import('@/views/client/rewards/charities/CharityHome.vue'),
                },
                {
                  name: 'Client_Rewards-Charities-CauseSingle',
                  path: 'causes/:causeSlug',
                  component: () =>
                    import('@/views/client/rewards/charities/CauseSingle.vue'),
                },
              ],
            },
            {
              path: 'store',
              children: [
                {
                  name: 'Client_Rewards-CustomCatalog-Home',
                  path: '',
                  component: () =>
                    import(
                      '@/views/client/rewards/customCatalog/CustomCatalogHome.vue'
                    ),
                },
                {
                  name: 'Client_Rewards-CustomCatalog-Item',
                  path: ':itemId',
                  component: () =>
                    import(
                      '@/views/client/rewards/customCatalog/CustomCatalogItem.vue'
                    ),
                },
              ],
            },
            {
              path: 'amazon',
              children: [
                {
                  name: 'Client_Rewards-Amazon-Home',
                  path: '',
                  component: () =>
                    import('@/views/client/rewards/amazon/AmazonHome.vue'),
                },
                {
                  name: 'Client_Rewards-Amazon-SearchResults',
                  path: 'search',
                  component: () =>
                    import('@/views/client/rewards/amazon/SearchResults.vue'),
                },
                {
                  name: 'Client_Rewards-Amazon-ProductDetails',
                  path: 'product/:asin',
                  component: () =>
                    import('@/views/client/rewards/amazon/ProductDetails.vue'),
                },
              ],
            },
            {
              path: 'marketplace',
              children: [
                {
                  name: 'Client_Rewards-Marketplace-List',
                  path: '',
                  component: () =>
                    import(
                      '@/views/client/rewards/marketplace/MarketplaceList.vue'
                    ),
                },
                {
                  name: 'Client_Rewards-Marketplace-ProductDetails',
                  path: 'product/:productId',
                  component: () =>
                    import(
                      '@/views/client/rewards/marketplace/ProductDetails.vue'
                    ),
                },
              ],
            },
            {
              path: 'redemptions',
              name: 'Client_Rewards-Redemption-History',
              component: () =>
                import('@/views/client/rewards/RedemptionHistory.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'page-not-found',
      component: PageNotFound,
    },
  ],
})

router.beforeEach(async (to) => {
  progressBar.start()
  const userStore = useUserStore()

  // Attempt to close all modals
  const { closeAllModals } = useModal()
  const modalContinue = await closeAllModals()
  if (modalContinue === false) return false

  const requiresAuth =
    to.matched.findIndex((r) => r.meta?.requireAuth === true) > -1
  if (!requiresAuth) return true

  // Required authentication
  const { user } = storeToRefs(userStore)

  if (!user.value) {
    toast.warning('Your session has expired, please login again.')
    userStore.logout()
    return {
      name: 'Login',
      query: { continue: encodeURIComponent(to.fullPath) },
    }
  }

  if (userStore.beenPopulated === false || userStore.teamPopulated === false)
    await new Promise((resolve) => {
      watch(
        () => userStore.beenPopulated && userStore.teamPopulated,
        (populated) => {
          if (populated === true) {
            try {
              // eslint-disable-next-line no-undef
              Sentry.setUser({ email: user.value.email, id: user.value.id })
            } catch (e) {
              console.warn('Error identifying Sentry user', e)
            }

            resolve()
          }
        }
      )
    })

  const isOnboard = to.matched.findIndex((r) => r.meta?.isOnboard === true) > -1
  if (user.value.done_onboard === false) {
    if (!isOnboard)
      return {
        name: 'Onboarding_Welcome',
      }
  }

  if (user.value.done_onboard === true && isOnboard) return { name: 'Home' }

  const requiredRoles = to.meta?.requiredRoles || []
  if (requiredRoles.length > 0) {
    // Make sure user has all required roles
    const validRoles = requiredRoles.every((role) =>
      user.value.roles.includes(role)
    )
    if (!validRoles) return false
  }

  /* if (requiredRoles.includes('admin') && to.meta?.requiredTeamPlan) {
    const requiredTeamPlans = to.meta.requiredTeamPlan
    const matchedPlans = intersection(requiredTeamPlans, team.active_plans)

    if (matchedPlans.length === 0) {
      openModal(FeatureUpsellModal, { requiredPlans: requiredTeamPlans })
      return false
    }
  } */

  const requiredPlan = to.meta?.requiredPlan || []
  if (requiredPlan.length > 0) {
    // Make sure user has any required plan
    const hasPlan = requiredPlan.includes(user.value.subscription_type)
    if (!hasPlan) return false
  }

  const requiredFeatures = to.meta?.requiredFeatures || []
  if (requiredFeatures.length > 0) {
    // Make sure user has all required roles
    const validFeatures = requiredFeatures.every((role) =>
      user.value.features.includes(role)
    )
    if (!validFeatures) return false
  }

  return true
})

router.afterEach(() => {
  progressBar.done()
})

export default router
